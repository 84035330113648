/* eslint-disable @typescript-eslint/no-floating-promises */
import { takeEvery, takeLatest, call, put, select, delay, all } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { addBathymetryDatasets,  addSelectedIslands,  clearMapContent, fastWaveConfigSet, getPointDecimals, setAreasOfInterest, setMeshExtent, setProj4String, setSelectedMesh, setSelectedOutline, showProjectionSystemDialog } from '../actions/mapContent'
import { abortDotMeshIsExportedCheck, addShoreline, checkMeshIsExported, checkingMeshIsExported, creatingMesh, creatingWorkspace, deleteProjection, getGebcoBathymetry, initAutoMesh, loadingAOI, setLoadingBathymetry, setLoadingGebco, loadingOutline, loadingShoreline, pendingImportSet, setCreateMeshPayload, setInterpolatingMesh, setMeshIsExported, setVtk, setVtkItemId, getCreateMeshPayload, setWorkspaceScenarios, loadingScenarios, resetAutoMesh, setLoadingIslands, setAutoShorelineParameters } from '../actions/createMesh'
import { getAOIS, getAbortDotMeshExportedCheck, getCoordSystems, getCreateAutoMeshPayload, getDomain, getFastWaveConfig, getIsMapReady, getProject, getShorelines, getWSScenarios, getWorkspcaceScenario } from '../reducers/state'
import { getCoordinateSystem, getProjectDatasets } from '../apis/metadataApi'
import { IFastWaveConfig } from '../model/IFastWaveConfig'
import { IWorkspaceGeometry,  IWorkspaceVariable,  addAOI, addBathymetry, addDomain,  addDrawnAOI,  addDrawnDomain,  addDrawnIslands,  addDrawnShoreline,  addIslands,  addOwnShoreline,  canCreateMesh,  createAutoMesh, createAutoShoreline, createWorkspace, deleteAOI, deleteBathymetry, deleteDomain, deleteIslands, deleteOwnShoreline, getAutoShorelineParameters, getDataExists, getScenarioOptions, getScenarioParameters, getScenarios, getWorkspace, getWorkspaceData, interpolateAutoMesh, meshIsExported } from '../apis/backendApi'
import { DATASETS, DOTMESH_FROM_MB, GET_WORKSPACE_INFO_DELAY, MESH, MESHTYPE, SHORELINE } from '../shared/constants'
import IProjection from '../components/mike-projection-select/model/IProjection'
import { IWorkspace } from '../model/IWorkspace'
import { IViewerBounds } from '../MikeVisualizer/lib/IMikeVisualizerModels'
import { AOI, AUTO_DOT_MESH, AUTO_MESH, DOMAIN, GEBCO, GEBCO_BATHYMETRY, ICreateMeshDataset, ICreateMeshInfo, ICreateMeshParameter, ISLANDS_TITLE } from '../reducers/createMesh'
import { addLayer, addLayerGroup, getLayerConfig, legendGroupReset, removeLayers/* , updateLayer */ } from '../actions/legend'
import {  /* backgroundLayers, */ meshInputLayers } from '../reducers/legend'
import { AOI_ID, AUTO_MESH_ID, DOMAIN_ID, ELEVATION, GEBCO_ID, ISLANDS_ID, OWN_SHORELINE, OWN_SHORELINE_ID } from '../components/Viewer'
import { deleteOutputFolder } from '../actions/projectContent'
import { IGetProject } from '../model/IGetProject'
import { IGetDataset } from '../model/IGetDataset'
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import { REPRESENTATION } from '../MikeVisualizer/lib/MikeVisualizerConstants';
import MikeVisualizerIO from '../MikeVisualizer/lib/MikeVisualizerIO';
import ICreateMeshScenarioOptions, { IMeshInputEntity } from '../model/CreateMeshScenarioOptions'
import { addError, addErrors } from '../actions/errors'
import { deleteMeshFromDLStorage, getDataLinkMeshStatus } from '../apis/dataLinkApi'
import { IMeshStatus } from '../model/IGetDataLinkMeshStatus'
import { getLayerOrderIndex, orderLayers } from '../helpers/fastwave'
import { intl } from '../main'
import { IAutoShorelineParameter } from '../model/IAutoShorelineParameter'

const { updateData } = MikeVisualizerLib;
const {_parseVtkDataXml} = MikeVisualizerIO;

export default function* watchCreateMesh() {
  yield takeEvery(ActionType.OUTLINE_UPLOADED, handleOutlineUploaded) 
  yield takeEvery(ActionType.AOIS_UPLOADED_OR_SELECTED, handleAOIUploadedOrSelected) 
  yield takeEvery(ActionType.SHORELINE_UPLOADED_OR_SELECTED, handleShorelineUploadedOrSelected) 
  yield takeEvery(ActionType.ISLANDS_UPLOADED_OR_SELECTED, handleIslandsUploadedOrSelected)
  yield takeEvery(ActionType.BATHYS_UPLOADED_OR_SELECTED, handleBathymetriesUploadedOrSelected) 
  yield takeEvery(ActionType.CREATE_WORKSPACE, handleCreateWorkspace) 
  yield takeEvery(ActionType.MESHTYPE_SET, handleSetMeshType) 
  yield takeEvery(ActionType.DELETE_PROJECTION, handleDeleteProjection) 
  yield takeEvery(ActionType.DRAWN_AREA_UPLOADED, handleDrawnAreaUploaded)
  yield takeEvery(ActionType.UPLOAD_DRAWNGEOMETRY, handleDrawnGeometry)
  yield takeEvery(ActionType.DELETE_VTKITEMS, handleDeleteVtkItems)
  yield takeEvery(ActionType.SET_SELECTED_OUTLINE, handleOutlineSelected)
  yield takeLatest(ActionType.CREATE_MESH, handleCreateMesh)
  yield takeEvery(ActionType.VTKITEM_SET, handleSetVtkItemId)
  yield takeEvery(ActionType.GET_GEBCO, handleGetGebcoBathymetry)
  // yield takeEvery(ActionType.GET_SHORELINE, handleGetShoreline)
  yield takeEvery(ActionType.LEGENDGROUP_ADD_LAYER, handleAddLayer)
  yield takeLatest(ActionType.INTERPOLATE_MESH, handleInterpolateMesh)
  yield takeEvery(ActionType.VTK_SET, handleSetVtk)
  yield takeLatest(ActionType.GET_MESH_IS_EXPORTED, handleCheckMeshIsExported)
  yield takeEvery(ActionType.GET_CREATE_MESH_SCENARIO_PARAMS, handleGetScenarioParamsAndOptions)
  yield takeLatest(ActionType.GET_SCENARIOS, handleGetScenarios)
  yield takeLatest(ActionType.CREATE_AUTO_SHORELINE, handleCreateAutoShoreline)
}

function* handleCreateAutoShoreline(action){
  const parameters = action.data;
  const project : IGetProject | null = yield select(getProject);  
  try{
    yield put(loadingShoreline());
    const autoShoreline: IWorkspaceGeometry = yield call(createAutoShoreline, project.id, parameters);      
    const vtk = yield call(getWorkspaceData, project.id, autoShoreline.id);
    if (vtk){
      yield put(setVtk(vtk,OWN_SHORELINE_ID, autoShoreline.name, project.id))
      yield put(addShoreline(autoShoreline))
    }
  }
  catch(error){
    console.log(error);
  }
  finally{
    yield put(loadingShoreline(false));
  } 
}

function* handleInterpolateMesh(action){
  const bathymetries = action.data;
  const project : IGetProject | null = yield select(getProject);  
  yield put(resetAutoMesh(true))
  yield put(deleteOutputFolder())
  try{     
    yield put(setInterpolatingMesh());
    try{
      const meshStatus = yield call(getDataLinkMeshStatus, project.id)
      if (meshStatus && meshStatus.length > 0){
        const dataLinkMesh = meshStatus.find((status: IMeshStatus) => status.meshName === DOTMESH_FROM_MB)    
        if (dataLinkMesh){
          yield call(deleteMeshFromDLStorage, project.id, DOTMESH_FROM_MB)
        }   
      }    
    }
    catch(error){
      console.log(error)
    } 
    const mesh = yield call(interpolateAutoMesh, project.id, bathymetries);
    if (mesh && mesh.outputIds && mesh.outputIds.length > 0){
      const vtk = yield call(getWorkspaceData,  project.id, mesh.outputIds[0]);
      if (vtk){
        yield put(setVtk(vtk, AUTO_MESH_ID, "", project.id));
      }     
    }
  }
  catch(error){
    console.log(error)
  }
  finally{
    yield put(setInterpolatingMesh(false))
  }  
}

function* handleAddLayer(action){
  const project : IGetProject | null = yield select(getProject);  
  const layerId = action.data && action.data.layer && action.data.layer.id ? action.data.layer.id : ""
  if (layerId && project){
    const vtkToBeCleared = [DOMAIN_ID, AOI_ID, GEBCO_ID]
    if (vtkToBeCleared.includes(layerId)){
      yield put(setVtk("", layerId, "", project.id))
    }
  }
}

function* handleSetVtk(action){  
  const { vtk, layerId, title, projectId } = action.data; 
  const blueColor = [0,0.6431372549019608,0.9254901960784314, 1]
  if (vtk){   
    switch (layerId) {
      case DOMAIN_ID: {
        const parsedVtpData = _parseVtkDataXml(vtk)  
        const bounds = parsedVtpData.getBounds() // xMin, xMax, yMin, yMax, zMin, zMax
        const meshExtent = [bounds[0], bounds[1], bounds[2], bounds[3], 0, 0] as IViewerBounds
        yield put(setMeshExtent(meshExtent))       
        updateData(vtk, DOMAIN_ID, blueColor,blueColor, {edgeVisibility: false, representation:1},3, false, undefined, null, false, false);
        yield put(addLayer(meshInputLayers, {groupTitle: meshInputLayers, title: DOMAIN,id: DOMAIN_ID,visible: true,isTwoD: false,loaded: true, order: getLayerOrderIndex(DOMAIN_ID), color: blueColor}));
        break;        
      }
      case AOI_ID: {
        updateData(vtk, AOI_ID, blueColor,blueColor, {edgeVisibility: false, representation:1},3, false, undefined, null, false, false);
        yield put(addLayer(meshInputLayers, {groupTitle: meshInputLayers,title: AOI,id: AOI_ID,visible: true,isTwoD: false,loaded: true, order: getLayerOrderIndex(AOI_ID),color: blueColor}));
        break;  
      }
/*       case SHORELINE_ID: {
        updateData(vtk, SHORELINE_ID, blueColor,blueColor, {edgeVisibility: false, representation:1},3, false, undefined, null, true);
        yield put(addLayer(meshInputLayers, {title: NOAA_SHORELINE,id: SHORELINE_ID,visible: true,isTwoD: false,loaded: true}));
        break; 
      } */
      case OWN_SHORELINE_ID: { 
        const { delete2DData } = MikeVisualizerLib
        delete2DData(OWN_SHORELINE_ID)      
        updateData(vtk, OWN_SHORELINE_ID, blueColor,blueColor, {edgeVisibility: false, representation:1},3, false, undefined, null, false, false);
        yield put(addLayer(meshInputLayers, {groupTitle: meshInputLayers,title: title,id: OWN_SHORELINE_ID,visible: true,isTwoD: false,loaded: true, order: getLayerOrderIndex(OWN_SHORELINE_ID),color: blueColor}));
        break;
      }
      case GEBCO_ID: {
        updateData(vtk, GEBCO_ID, [1,1,1, 1],[1,1,1, 1], {edgeVisibility: false, representation:2},6, true, {gradientPreset:"erdc_rainbow_bright", numberOfLegends: 10, numberOfSignificantDigits:3}, ELEVATION, false, true);
        yield put(addLayer(meshInputLayers, {groupTitle: meshInputLayers,title: GEBCO_BATHYMETRY,id: GEBCO_ID,visible: true,isTwoD: false,loaded: true, order: 0}));
        yield call(orderLayers); 
        break; 
      }
      case ISLANDS_ID: {
        const { delete2DData } = MikeVisualizerLib
        delete2DData(ISLANDS_ID)   
        updateData(vtk, ISLANDS_ID, blueColor,blueColor, {edgeVisibility: false, representation:1},3, false, undefined, null, false, false);
        yield put(addLayer(meshInputLayers, {groupTitle: meshInputLayers,title: ISLANDS_TITLE,id: ISLANDS_ID,visible: true,isTwoD: false,loaded: true, order: getLayerOrderIndex(ISLANDS_ID),color: blueColor}));
        yield call(orderLayers); 
        break; 
      }
      case AUTO_MESH_ID: {
        const parsedVtpData = _parseVtkDataXml(vtk)  
        const fieldData = parsedVtpData.getPointData();
        const dataArrays = fieldData.getArrays();
        const elevationName = dataArrays.find((da) => (da.getName() as string).toLowerCase() === ELEVATION)    
        if (elevationName !== undefined){        
          const points = parsedVtpData.getPoints()        
          for (let i = 0; i < points.getData().length; i++) {
            const point = points.getPoint(i)
            points.setPoint(i, point[0], point[1], 0)
          }
          updateData(parsedVtpData, AUTO_MESH_ID, blueColor,[1,1,0, 1], {edgeVisibility: true, representation:2},2, true, {gradientPreset:"erdc_rainbow_bright", numberOfLegends: 10, numberOfSignificantDigits:3}, elevationName.getName(), false, false);
          yield put(abortDotMeshIsExportedCheck(false))
          yield put(checkMeshIsExported(projectId))
        }
        else{
          updateData(vtk, AUTO_MESH_ID, blueColor,[1,1,0, 1], REPRESENTATION.WIREFRAME,4, false, null, null, true);         
        }
        yield put(addLayer(meshInputLayers, {groupTitle: meshInputLayers,title: AUTO_MESH,id: AUTO_MESH_ID,visible: true,isTwoD: false,loaded: true, order: getLayerOrderIndex(AUTO_MESH_ID), color: blueColor}));
       
        yield call(orderLayers); 
        break; 
      }
      default: {
/*         const parsedVtpData = _parseVtkDataXml(vtk)  
        const fieldData = parsedVtpData.getFieldData().getArrays();      
        const fieldNames = fieldData.map((da) => da.getName());
        console.log(fieldNames) */
/*         const project: IGetProject | null = yield select(getProject);
        const canDelete = project && project.capabilities && project.capabilities.canDeleteContent
        updateData(vtk,layerId,blueColor,blueColor, {edgeVisibility: false, representation:1},5, false, undefined, null, false, false);      
        yield put(updateLayer(backgroundLayers, {groupTitle: backgroundLayers,title: title,id: layerId,visible: true,isTwoD: false,loaded: true, loading: false, order: getLayerOrderIndex(layerId), color: blueColor, canDelete: canDelete}));
        yield call(orderLayers);  */
        break;
      }
    } 
  } 
}

function* handleSetVtkItemId(action){
  const project : IGetProject | null = yield select(getProject);
  const { vtkItemId, layerId } = action.data;
  try{
    const vtk = yield call(getWorkspaceData,  project.id, vtkItemId); 
    if (vtk){
      yield put(setVtk(vtk, layerId, "", project.id)); 
    } 
  }
  catch (error) {          
    console.log(error)    
  }
}

function* handleCheckMeshIsExported(action){
  const projectId = action.data
  const abort = yield select(getAbortDotMeshExportedCheck)
  
  yield put(setMeshIsExported(false))
  yield put(checkingMeshIsExported(true))
  let meshExported;
  const timeOut = 300000 // 5 minutes
  let time = 0
  while (!abort && time < timeOut) {
    const project : IGetProject | null = yield select(getProject);
    if (project && project.id && project.id !== projectId){
      break;
    }
    try{
      meshExported = yield call(meshIsExported, project.id)
      if (meshExported){
        break;
      }      
    }
    catch  {    
      yield put(checkingMeshIsExported(false))      
      break  // Stop fetching when it starts to fail - happens e.g. when browser got inactive     
    }   

    yield delay(5000) 
    time += 5000
  }
  yield put(checkingMeshIsExported(false))
  if (meshExported){    
    const datasets = yield call(getProjectDatasets, projectId)
    const dotMeshFile = datasets.find((d: IGetDataset) => d.name.startsWith(AUTO_DOT_MESH))
    if (dotMeshFile !== undefined){
      const config: IFastWaveConfig = yield select(getFastWaveConfig)
      const noUpdateNeeded = config.mesh_file && config.mesh_file.dataset_id === dotMeshFile.id
      if (!noUpdateNeeded){
        yield put(setSelectedMesh(dotMeshFile, projectId))
      }      
    }
    yield put(setMeshIsExported())   
  }
}

function* handleCreateMesh(){
  yield put(creatingMesh());
  const project : IGetProject | null = yield select(getProject);
  let meshCanBeCreated = false;
  try{  
    const response = yield call(canCreateMesh, project.id) 
    if (response && response.length === 0){
      meshCanBeCreated = true
    }      
    else{   
      yield put(creatingMesh(false));  
      yield put(addErrors(response))
    }                  
  } 
  catch(error) {   
    if (error){
      yield put(creatingMesh(false));
      yield put(addError(error))
    }    
  }
  
  if (meshCanBeCreated){  
    yield put(resetAutoMesh())
    yield put(deleteOutputFolder())
    const shorelines: Array<ICreateMeshDataset> = yield select(getShorelines);
    const domain: ICreateMeshDataset | null = yield select(getDomain)
    const domainId = domain ? domain.id : ""
    const aois: Array<ICreateMeshDataset> = yield select(getAOIS)
    const aoiId = aois.length > 0 ? aois[aois.length -1].id : "" 
    const ownShoreline = shorelines.find((s: ICreateMeshDataset) => s.selected && s.name.startsWith(OWN_SHORELINE))
    const shorelineId = ownShoreline !== undefined ? ownShoreline.id : ""
    const meshPayload: ICreateMeshInfo = yield select(getCreateAutoMeshPayload)
    const params = meshPayload.parameterDescriptions;
    const payload = params.map((p: ICreateMeshParameter) => {
      if (p.value && (p.entityId || p.entityType.toLowerCase() === MESH)){
        return {name: p.name, value: p.value, entityId: p.entityId, entityType: p.entityType}
      }
      else{
        const type = p.entityType
        const vtkItemId = type === DOMAIN ? domainId : type === AOI ? aoiId : shorelineId
        return {name: p.name, value: p.defaultValue, entityId: vtkItemId, entityType: p.entityType}
      }    
    })
    try{           
      const mesh = yield call(createAutoMesh, project.id, payload); // , useAutoShoreline: ownShoreline === undefined    
      if (mesh && mesh.outputIds && mesh.outputIds.length > 0){    
        const vtk = yield call(getWorkspaceData,  project.id, mesh.outputIds[0]);
        if (vtk){
          yield put(setVtk(vtk, AUTO_MESH_ID, "", project.id));
        }
      }
    }
    catch(error){
      console.log(error)
    }
    finally{
      yield put(creatingMesh(false));
    }   
  }
}

function* handleDeleteVtkItems(action){
  const project : IGetProject | null = yield select(getProject);
  const {itemsToDelete, datasetType} = action.data

  if (datasetType === DATASETS.OUTLINE){
    try{  
      yield put(abortDotMeshIsExportedCheck())
      yield call(deleteDomain, project.id) 
      yield put(removeLayers(meshInputLayers, 
        [ISLANDS_ID, DOMAIN_ID,GEBCO_ID,AUTO_MESH_ID]
      ))  
    }
    catch(error){
      console.log(error)
    } 
  } 
  else if (datasetType === DATASETS.AREAOFINTEREST){
    try{     
      yield call(deleteAOI, project.id) 
      yield put(removeLayers(meshInputLayers, [AOI_ID]))   
    }
    catch(error){
      console.log(error)
    }
  }
  else if (datasetType === DATASETS.BATHYMETRY && itemsToDelete && itemsToDelete.length > 0){
    try{      
      yield put(abortDotMeshIsExportedCheck())
      yield all(itemsToDelete.map((itemToDelete: ICreateMeshDataset) => call(deleteBathymetry, project.id, itemToDelete.id)))
      yield put(removeLayers(meshInputLayers, itemsToDelete.map((itemToDelete: ICreateMeshDataset) => {return { title: itemToDelete.name, id: itemToDelete.id, visible: true, isTwoD: false, loaded: true}})))
    }
    catch(error){
      console.log(error)
    }
  }
  else if (datasetType === DATASETS.ISLANDS){
    try{      
      yield put(abortDotMeshIsExportedCheck())
      yield all(itemsToDelete.map((itemToDelete: ICreateMeshDataset) => call(deleteIslands, project.id, itemToDelete.id)))
      yield put(removeLayers(meshInputLayers, 
        [ ISLANDS_ID,  AUTO_MESH_ID ]
      ))      
    }
    catch(error){
      console.log(error)
    }
  }
  else if (datasetType === DATASETS.OWN_SHORELINE){
    try{      
      yield put(abortDotMeshIsExportedCheck())
      yield all(itemsToDelete.map((itemToDelete: ICreateMeshDataset) => call(deleteOwnShoreline, project.id, itemToDelete.id))) 
      yield put(removeLayers(meshInputLayers, 
        [ OWN_SHORELINE_ID,  AUTO_MESH_ID ]
      ))   
    }
    catch(error){
      console.log(error)
    }
  }
}

function* handleDrawnGeometry(action){
  const project = yield select(getProject)
  const { fc, drawing } = action.data
  if (fc && fc.features && fc.features.length > 0){  
    const feat = JSON.stringify(fc)
    switch (drawing){
      case DATASETS.OWN_SHORELINE:{
        try{
          yield put(loadingShoreline())
          const ownShoreline: IWorkspaceGeometry = yield call(addDrawnShoreline, project.id, feat)     
          const vtk = yield call(getWorkspaceData, project.id, ownShoreline.id);
          if (vtk){
            yield put(setVtk(vtk,OWN_SHORELINE_ID, ownShoreline.name, project.id))
            yield put(addShoreline(ownShoreline))
          }
        }
        catch(error){
          console.log(error)
        } 
        finally{
          yield put(loadingShoreline(false))
        } 
        break; 
      }
      case DATASETS.OUTLINE: {
        try{     
            yield put(loadingOutline())
            yield put(removeLayers(meshInputLayers, 
              [
                DOMAIN_ID,GEBCO_ID,
                // SHORELINE_ID
              ]
            ))  
            const geometry: IWorkspaceGeometry = yield call(addDrawnDomain, project.id, feat)
            if (geometry){
              yield put(setSelectedOutline(geometry)) 
              yield put(getGebcoBathymetry(project.id))
            }            
        }
        catch(error){ 
          console.log(error)
        }
        finally{
          yield put(loadingOutline(false))
        }
        break;      
      }
      case DATASETS.AREAOFINTEREST:{
        try{    
          yield put(loadingAOI())
          yield put(removeLayers(meshInputLayers, 
            [ AOI_ID]
          ))  
          const aoi: IWorkspaceGeometry = yield call(addDrawnAOI, project.id, feat)
          yield put(setAreasOfInterest([{...aoi, selected: true}]))
          const vtk = yield call(getWorkspaceData,  project.id, aoi.id);
          if (vtk){
            yield put(setVtk(vtk, AOI_ID, "", project.id));
          }  
        }
        catch(error){
          console.log(error)
        }
        finally{
          yield put(loadingAOI(false))
        }
        break;      
      }
      case DATASETS.ISLANDS:{        
        try{    
          yield put(setLoadingIslands())  
          const islands: IWorkspaceGeometry = yield call(addDrawnIslands, project.id, feat)
          yield put(addSelectedIslands({...islands, selected: true}))
          const vtk = yield call(getWorkspaceData,  project.id, islands.id);
          if (vtk){
            yield put(setVtk(vtk, ISLANDS_ID, ISLANDS_TITLE, project.id));
          }  
        }
        catch(error){
          console.log(error)
        }
        finally{
          yield put(setLoadingIslands(false))
        }
        break;      
      }
      default:
        break;      
    }    
  }  
}

function* handleDeleteProjection(){  
    const config: IFastWaveConfig = yield select(getFastWaveConfig)
    const mapIsReady: boolean = yield select(getIsMapReady)
    if (mapIsReady){
      yield put(clearMapContent())
    }   
    yield put(fastWaveConfigSet({...config, createMeshConfig: { targetSrid: null, validBounds:null }, mesh_file: null}, true)) 
}

function* handleGetScenarioParamsAndOptions(){
  const scenario: string = yield select(getWorkspcaceScenario)
  const decapitalize = (text: string) => {
    return text.charAt(0).toLowerCase() + text.slice(1)
  }
  let scenarioSupportsShoreline = false;
  try{
    const createMeshInfo: ICreateMeshInfo = yield call(getScenarioParameters, scenario)
    const createMeshOptions: ICreateMeshScenarioOptions = yield call(getScenarioOptions, scenario)
    if (createMeshOptions && createMeshOptions.entities){
      scenarioSupportsShoreline = createMeshOptions.entities.find((entity) => entity.type.toLowerCase() === SHORELINE) !== undefined;
    }    
    const parameterDescriptions = createMeshInfo.parameterDescriptions.map((pd: ICreateMeshParameter) => {
      const paramInfo = intl.formatMessage({id: 'createMesh.parameterInfo_' + pd.name}) 
      if (paramInfo !== 'createMesh.parameterInfo_' + pd.name){
        const splitttedInfo = paramInfo.split(".")
        const filterEmpty =  splitttedInfo.filter((i: string) => i !== "") 
        return {...pd, name: decapitalize(pd.name), info: filterEmpty.map((i: string) => i.trim() + ".")}
      }      
      else{
        return {...pd, name: decapitalize(pd.name) }
      }      
    })
    yield put(setCreateMeshPayload({...createMeshInfo, parameterDescriptions}, createMeshOptions && createMeshOptions.entities ?  createMeshOptions.entities : new Array<IMeshInputEntity>() ))
  }
  catch(error){
    console.log(error)
  } 
  let shorelineParams = Array<ICreateMeshParameter>();
  if (scenarioSupportsShoreline){
    try{
      const autoShorelineParameters: IAutoShorelineParameter = yield call(getAutoShorelineParameters);
      if (autoShorelineParameters && autoShorelineParameters.parameterDescriptions){
        shorelineParams = autoShorelineParameters.parameterDescriptions
        
      }  
    }
    catch(error){
      console.log(error)
    }
    finally{
      yield put(setAutoShorelineParameters(shorelineParams))
    }
  }

}

function* handleGetScenarios(){  
  try{
    yield put(loadingScenarios())
    const wsScenarios = yield call(getScenarios)
    yield put(setWorkspaceScenarios(wsScenarios))
  }
  catch(error){
    console.log(error)
  } 
  finally{
    loadingScenarios(false)
  }   
}

function* handleSetMeshType(action){  
  const { changeToType, updateConfig } = action.data
  
  yield put(legendGroupReset())
  if (changeToType === MESHTYPE.AUTOMESH){    
    yield put(addLayerGroup(meshInputLayers))     
  }
  
  if (updateConfig){ 
    const config: IFastWaveConfig = yield select(getFastWaveConfig)
    const mapIsReady: boolean = yield select(getIsMapReady)
    const project : IGetProject | null = yield select(getProject); 
    if (mapIsReady){
      yield put(clearMapContent())
    }
    yield put(deleteOutputFolder())    
    yield put(setSelectedMesh(null, project.id))
    if (changeToType === MESHTYPE.AUTOMESH){
      yield put(fastWaveConfigSet({ 
        createMeshConfig: { targetSrid: null},        
        setup: config.setup
      }, true))
    } else if (changeToType === MESHTYPE.UPLOAD){
      try{     
        yield put(fastWaveConfigSet({ 
          setup: config.setup
        }, true))
      }
      catch(error){
        console.log(error)
      }       
    }
  }
  
  const project = yield select(getProject)
  if (project){
    yield put(getLayerConfig(project.id))   
  }  
}

function* handleCreateWorkspace(action){
  const epsgCode = action.data
  const project : IGetProject | null = yield select(getProject);
  const scenario: string = yield select(getWorkspcaceScenario)
  const scenarios: Array<string> = yield select(getWSScenarios)
  
  if (epsgCode && project && scenario){   
    try{   
      yield put(creatingWorkspace())  
      const coordinateSystems: Array<IProjection> = yield select(getCoordSystems)
      let proj4String;
      let wkt;
      const cs = coordinateSystems.find((p: IProjection) => p.id === epsgCode)
      if (cs && cs.proj4String){
        proj4String = cs.proj4String
        wkt = cs.wkt
      }
      else{
        const coordinateSystem: IProjection = yield call(getCoordinateSystem, epsgCode.toString())
        proj4String = coordinateSystem.proj4String
        wkt = coordinateSystem.wkt
      }
      yield put(getPointDecimals(wkt))
      const existingWorkspace: IWorkspace = yield call(getWorkspace, project.id) 
      const config: IFastWaveConfig = yield select(getFastWaveConfig)     
      if (!existingWorkspace || existingWorkspace.epsgCode !== epsgCode || existingWorkspace.scenario !== scenario) {         
        const mapIsReady: boolean = yield select(getIsMapReady)
        if (mapIsReady){
          yield put(clearMapContent())
        }
        yield put(initAutoMesh(scenarios, scenario));
        yield put(getCreateMeshPayload())
        const createdWorkspace: IWorkspace = yield call(createWorkspace, epsgCode, project.id, scenario);        
       // const boundsTooHuge = getBoundsTooHuge(epsgCode,createdWorkspace.bounds as IViewerBounds )   
        yield put(setProj4String(proj4String, epsgCode, createdWorkspace.bounds as IViewerBounds));
        yield put(fastWaveConfigSet(
          {
            createMeshConfig: { targetSrid: epsgCode },      
            setup: config.setup
          }, true));
      }
      else{
        yield put(getCreateMeshPayload());
        // const boundsTooHuge = getBoundsTooHuge(epsgCode,existingWorkspace.bounds as IViewerBounds )   
        yield put(setProj4String(proj4String, epsgCode, existingWorkspace.bounds as IViewerBounds));
        yield put(fastWaveConfigSet(
          {
            ...config,
            createMeshConfig: { targetSrid: epsgCode },            
          }, true));
      }      
    }
    catch(error){
      console.log(error)
    }
    finally{
      yield put(creatingWorkspace(false))
    }  
  }
  else{
    yield put(deleteProjection())
  }
}

function* handleOutlineUploaded(action){
  yield put(showProjectionSystemDialog(false))
  yield put(pendingImportSet(null))
  const datasetId = action.data
  if (datasetId){   
    try{
      yield put(removeLayers(meshInputLayers, 
        [DOMAIN_ID,GEBCO_ID,AUTO_MESH_ID]
      ))  
      yield put(loadingOutline())
      yield put(setLoadingGebco())
      const project : IGetProject | null = yield select(getProject);
      const geometry: IWorkspaceGeometry = yield call(addDomain, project.id, datasetId)
      if (geometry !== undefined){               
        yield put(setSelectedOutline(geometry));
        yield put(getGebcoBathymetry(project.id))
      }
    } 
    catch {
      yield put(loadingOutline(false))
      yield put(setLoadingGebco(false))
    }         
  }  
}

function* handleGetGebcoBathymetry(action){
  const projectId = action.data
  yield put(setLoadingGebco())
  let gebco;
  const timeOut = 600000 // 10 minutes
  let time = 0
  while (time < timeOut) {
    const project : IGetProject | null = yield select(getProject);
    if (project && project.id && project.id !== projectId){
      break;
    }
    try{
      const workspace: IWorkspace = yield call(getWorkspace, project.id )
      if (workspace.variables.length > 0){
        gebco = workspace.variables.find((variable: IWorkspaceVariable) => variable.name.startsWith(GEBCO));
      }  
      
      if (gebco !== undefined){
        break;
      }      
    }
    catch {          
      break  // Stop fetching when it starts to fail - happens e.g. when browser got inactive     
    }
    yield delay(GET_WORKSPACE_INFO_DELAY) // 3 seconds
    time += GET_WORKSPACE_INFO_DELAY
  }
                  
  if (gebco !== undefined){
    let exists;
    time = 0
    while (time < timeOut) {
      const project : IGetProject | null = yield select(getProject);
      if (project && project.id && project.id !== projectId){
        break;
      }
      try{
        exists = yield call(getDataExists, project.id, gebco.id)
        if (exists){
          break;
        }      
      }
      catch {          
        break  // Stop fetching when it starts to fail - happens e.g. when browser got inactive     
      }

      yield delay(GET_WORKSPACE_INFO_DELAY) 
      time += GET_WORKSPACE_INFO_DELAY
    }
    if (exists){
      yield put(addBathymetryDatasets([{...gebco, name: GEBCO_BATHYMETRY, selected: true}]))  
      yield put(setVtkItemId(gebco.id, GEBCO_ID));  
    }            
  }
  yield put(setLoadingGebco(false))     
}

/* function* handleGetShoreline(){
  const project : IGetProject | null = yield select(getProject);
  let shoreline; 
  const timeOut = 1800000 // 0,5 hour
  let time = 0
  while (time < timeOut) {
    try{
      const workspace: IWorkspace = yield call(getWorkspace, project.id )
      if (workspace.geometries.length > 0){    
        shoreline = workspace.geometries.find((geometry: IWorkspaceGeometry) =>  {
          const name = geometry.name
          const isOwnShoreline = name.startsWith(SHORELINE)
          // const isNoaaShoreline = name === NOAASHORELINE
          return isOwnShoreline // || isNoaaShoreline
        })
      }  
      
      if (shoreline !== undefined){
        break;
      }      
    }
    catch (error) {          
      break  // Stop fetching when it starts to fail - happens e.g. when browser got inactive     
    }
    yield delay(5000) 
    time += 5000
  }
                  
  if (shoreline !== undefined){    
    yield put(setVtkItemId(shoreline.id, SHORELINE_ID));              
  }     
} */

function* handleOutlineSelected(action){
  const project : IGetProject | null = yield select(getProject);
  const selectedDataset = action.data   
  if (selectedDataset){
    const vtk = yield call(getWorkspaceData,  project.id, selectedDataset.id);
    if (vtk){
      yield put(setVtk(vtk, DOMAIN_ID, "", project.id));
    } 
  }  
}

function* handleBathymetriesUploadedOrSelected(action){
  const { updateData } = MikeVisualizerLib;
  const project : IGetProject | null = yield select(getProject);
  yield put(showProjectionSystemDialog(false))
  yield put(pendingImportSet(null))
  const datasetIds = action.data 
  if (datasetIds && datasetIds.length > 0){   
    try{
      yield put(setLoadingBathymetry())
      const bathymetries = yield all(datasetIds.map(datasetId => call(addBathymetry, project.id, datasetId)))
      yield put(addBathymetryDatasets(bathymetries.map((b: IWorkspaceVariable) => {return {...b, selected: true}} )))
      const vtks = yield all(bathymetries.map((b: IWorkspaceVariable) => call(getWorkspaceData, project.id, b.id)))      
      vtks.forEach((vtk: string, index: number,) => {        
        updateData(vtk, bathymetries[index].id, [0.9490196078431372, 0.9607843137254902, 0.9686274509803922, 1],
          [0.043137254901960784,0.27058823529411763,0.4, 0.8], {edgeVisibility: false, representation: 2}, 6, false, undefined, null, true, true);
      });
      yield all(bathymetries.map((b: IWorkspaceVariable, index: number) => put(addLayer(meshInputLayers, {
        groupTitle:meshInputLayers,
        title: b.name,
        id: b.id,
        visible: true,
        isTwoD: false,
        loaded: true,
        order: (index * -1) - 1
      }))))
    }
    catch(error){
      console.log(error)      
    } 
    finally{
      yield put(setLoadingBathymetry(false))
    }
        
  }  
}

function* handleShorelineUploadedOrSelected(action){
  const project : IGetProject | null = yield select(getProject);
  yield put(showProjectionSystemDialog(false))
  yield put(pendingImportSet(null))
  const datasetId = action.data 
  if (datasetId){   
    try{
      yield put(loadingShoreline())
      const ownShoreline: IWorkspaceGeometry = yield call(addOwnShoreline, project.id, datasetId)     
      const vtk = yield call(getWorkspaceData, project.id, ownShoreline.id);
      if (vtk){
        yield put(setVtk(vtk,OWN_SHORELINE_ID, ownShoreline.name, project.id))
        yield put(addShoreline(ownShoreline))
      }
    }
    catch(error){
      console.log(error)
    } 
    finally{
      yield put(loadingShoreline(false))
    }  
  }  
}

function* handleIslandsUploadedOrSelected(action){  
  const project : IGetProject | null = yield select(getProject);
  yield put(showProjectionSystemDialog(false))
  yield put(pendingImportSet(null))
  const datasetId = action.data 
  if (datasetId){   
    try{
      yield put(setLoadingIslands())      
      const islands: IWorkspaceGeometry = yield call(addIslands, project.id, datasetId)
      yield put(addSelectedIslands({...islands, selected: true}))
      const vtk = yield call(getWorkspaceData,  project.id, islands.id);
      if (vtk){
        yield put(setVtk(vtk, ISLANDS_ID, ISLANDS_TITLE, project.id));
      }
    }
    catch(error){
      console.log(error)      
    } 
    finally{
      yield put(setLoadingIslands(false))
    }        
  }  
}

function* handleAOIUploadedOrSelected(action){  
  const project : IGetProject | null = yield select(getProject);
  yield put(showProjectionSystemDialog(false))
  yield put(pendingImportSet(null))
  const datasetIds = action.data 
  if (datasetIds && datasetIds.length > 0){   
    try{
      yield put(loadingAOI())
      yield put(removeLayers(meshInputLayers, 
        [ AOI_ID]
      ))  
      const aoi: IWorkspaceGeometry = yield call(addAOI, project.id, datasetIds[0])
      yield put(setAreasOfInterest([{...aoi, selected: true}]))
      const vtk = yield call(getWorkspaceData,  project.id, aoi.id);
      if (vtk){
        yield put(setVtk(vtk, AOI_ID, "", project.id));
      }
    }
    catch(error){
      console.log(error)
      
    } 
    finally{
      yield put(loadingAOI(false))
    }
        
  }  
}

function* handleDrawnAreaUploaded(action){
  const config: IFastWaveConfig = yield select(getFastWaveConfig)
  const project : IGetProject | null = yield select(getProject);
  const { datasetId, datasetType } = action.data
  if (project && datasetId && config && config.createMeshConfig){   
    try{     
      if (datasetType === DATASETS.OUTLINE){
        yield put(removeLayers(meshInputLayers, 
          [
            DOMAIN_ID,GEBCO_ID,
            // SHORELINE_ID
          ]
        ))  
        const geometry: IWorkspaceGeometry = yield call(addDomain, project.id, datasetId)
        yield put(setSelectedOutline(geometry))       
      }
      else if (datasetType === DATASETS.AREAOFINTEREST){
        yield put(removeLayers(meshInputLayers, 
          [ AOI_ID]
        ))  
        const aoi: IWorkspaceGeometry = yield call(addAOI, project.id, datasetId)
        yield put(setAreasOfInterest([{...aoi, selected: true}]))
        const vtk = yield call(getWorkspaceData,  project.id, aoi.id);
        if (vtk){
          yield put(setVtk(vtk, AOI_ID, "", project.id));
        }       
      }
    }
    catch(error){
      console.log(error)
    }      
  }  
}